import { buildArtisnHeaders } from "@multicines/services";
import { getShoppingCart } from "artisn/shopping-cart";
import { validateShoppingCart } from "artisn/shopping-cart";
import { ShoppingCart } from "artisn/types";

import CONSTANTS from "config/constants";

const { ARTISN, API } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { API_URL } = API;

export const cleanShoppingCartAlerts = (cart: ShoppingCart): ShoppingCart => {
  if (!cart) return cart;
  // TODO: change to structuredClone when update typescript to 4.0
  const purgedCart = JSON.parse(JSON.stringify(cart));
  const stores = purgedCart.stores;
  for (const storeKey in stores) {
    const store = stores[storeKey];
    store.alerts = [];
    const products = store.products;
    for (const productKey in products) {
      const product = products[productKey];
      product.alerts = [];
    }
    store.products = products;
  }
  purgedCart.stores = stores;
  return purgedCart;
};

export const checkCartBenefitsAlerts = async (
  isAnonymous: boolean,
  uid: string
) => {
  // Check alerts for benefits
  const shoppingCart = await getShoppingCart({
    shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
    anonymous: isAnonymous,
    accountId: ACCOUNT_ID,
    customerId: uid
  });

  const { benefits } = shoppingCart ?? {};

  if (benefits) {
    const benefitsHasAlerts = Object.values(benefits).some(benefit => {
      //@ts-ignore Property alerts is not typed in the sdk
      return benefit?.alerts && benefit?.alerts?.length !== 0;
    });

    if (benefitsHasAlerts) {
      throw new Error("Error al validar los beneficios del carrito de compras");
    }
  }
};

/**
 * Utility to validate the shopping cart and throws an error if the validation fails.
 *
 * @since 3.0.0
 * @param {number} lat Latitude.
 * @param {number} lng Longitude.
 * @param {boolean}isAnonymous If the user is anonymous.
 */
export const validateShoppingCartUtility = async (
  isAnonymous: boolean,
  uid: string
) => {
  const headers = await buildArtisnHeaders();

  const shoppingCartValidations = await validateShoppingCart(
    {
      apiURL: API_URL,
      // TODO: this is no necessary?
      // latitude: lat,
      // longitude: lng,
      anonymous: isAnonymous,
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      accountId: ACCOUNT_ID,
      customerId: uid
    },
    headers
  );

  const { products, stores } = shoppingCartValidations ?? {};
  stores?.forEach(store => {
    const { type } = store;
    switch (type) {
      case "IS_OPEN":
        throw new Error(
          "Tienda cerrada, intenta volver luego para realizar tus compras"
        );
      case "OUT_OF_COVERAGE":
        throw new Error(
          "Estás fuera de cobertura, intenta cambiar tu ubicación para continuar"
        );
      default:
        throw new Error("Ocurrió un error al validar el carrito");
    }
  });

  if (products?.length) {
    throw new Error("Error al validar los productos del carrito de compras");
  }

  await checkCartBenefitsAlerts(isAnonymous, uid);
};
